import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/homes-for-sale',
    name: 'Homes for Sale',
    component: () => import('../views/HomesForSale.vue'),
  },
  {
    path: '/commercial-for-sale',
    name: 'Commercial for Sale',
    component: () => import('../views/CommercialForSale.vue'),
  },
  {
    path: '/commercial-for-rent',
    name: 'Commercial for Rent',
    component: () => import('../views/CommercialForRent.vue'),
  },
  {
    path: '/open-for-inspection',
    name: 'Open for Inspections',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/market-updates',
    name: 'Market Updates',
    component: () => import('../views/MarketUpdates.vue'),
  },
  {
    path: '/rent',
    name: 'Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/homes-for-rent',
    name: 'Homes for Rent',
    component: () => import('../views/HomesForRent.vue'),
  },
  {
    path: '/find-a-property-manager',
    name: 'Find a Property Manager',
    component: () => import('../views/Management.vue'),
  },
  {
    path: '/forms/request-an-appraisal',
    name: 'Request an Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/forms/notice-of-leave',
    name: 'Notice of Leave',
    component: () => import('../views/NoticeOfLeave.vue'),
  },
  {
    path: '/forms/repair-request',
    name: 'Repair Request',
    component: () => import('../views/RepairRequest.vue'),
  },
  {
    path: '/sold',
    name: 'Sold',
    component: () => import('../views/Sold.vue'),
  },
  {
    path: '/find-a-real-estate-agent',
    name: 'Find a Real Estate Agent',
    component: () => import('../views/MeetTheTeam.vue'),
  },
  {
    path: '/selling-with-us',
    name: 'Selling With Us',
    component: () => import('../views/SellingWithUs.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/forms/superannuation-appraisals',
    name: 'Superannuation Appraisals',
    component: () => import('../views/SuperannuationAppraisals.vue'),
  },
  {
    path: '/new-properties',
    name: 'New Properties',
    component: () => import('../views/NewProperties.vue'),
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import('../views/Management.vue'),
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('../views/About.vue'),
  },

  {
    path: '/our-difference',
    name: 'Our Difference',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/our-agents',
    name: 'Our Agents',
    component: () => import('../views/MeetTheTeam.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/tv-display/sales',
    name: 'TV Display Sales',
    component: () => import('../views/TVDisplaySales.vue'),
    meta: {
      hideAncillaries: true, // Disables the header and footer
    },
  },
  {
    path: '/tv-display/rentals',
    name: 'TV Display Rentals',
    component: () => import('../views/TVDisplayRentals.vue'),
    meta: {
      hideAncillaries: true, // Disables the header and footer
    },
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
