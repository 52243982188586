


































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import RangeSlider from 'client-website-ts-library/components/RangeSlider.vue';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import ToggleButton from './ToggleButton.vue';

@Component({
  components: {
    MultiSelect,
    RangeSlider,
    ToggleButton,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'BUY' })
  private readonly methodOfSale!: string;

  private mos = '';

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private suburbs: MultiSelectOption[] = [];

  private selectedSuburbs: MultiSelectOption[] = [];

  private beds = 0;

  private baths = 0;

  private salesPriceMin = 0;

  private salesPriceMax = 0;

  private rentalPriceMin = 0;

  private rentalPriceMax = 0;

  private areaMin = 0;

  private areaMax = 0;

  private formatCurrency = (val: number): string => `$${val.toLocaleString('en-AU')}`;

  private formatArea = (val: number): string => `${val.toLocaleString('en-AU')}sqm`;

  private commercialMos = 'buy';

  mounted() {
    this.updateMos();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale;
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));
      });
    }, 2000);
  }

  @Watch('mos')
  @Watch('beds')
  @Watch('baths')
  @Watch('salesPriceMin')
  @Watch('salesPriceMax')
  @Watch('areaMin')
  @Watch('areaMax')
  updateFilter() {
    this.filter.Categories = [];
    this.filter.Statuses = [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ];

    this.filter.MinLandArea = this.areaMin === 0 ? '' : this.areaMin;
    this.filter.MaxLandArea = this.areaMax === 0 ? '' : this.areaMax;

    switch (this.mos) {
      case 'BUY':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
        ];

        this.filter.MinPrice = this.salesPriceMin;
        this.filter.MaxPrice = this.salesPriceMax;
        break;
      case 'RENT':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];

        this.filter.MinPrice = this.rentalPriceMin;
        this.filter.MaxPrice = this.rentalPriceMax;
        break;
      case 'COMMERCIAL':
        this.filter.Categories = [
          ListingCategory.Commercial,
          ListingCategory.CommercialLand,
        ];

        this.filter.MinPrice = this.salesPriceMin;
        this.filter.MaxPrice = this.salesPriceMax;
        break;
      case 'SOLD':
        this.filter.Statuses = [
          ListingStatus.Sold,
        ];

        this.filter.MinSoldPrice = this.salesPriceMin;
        this.filter.MaxSoldPrice = this.salesPriceMax;
        break;
      default:
        this.filter.Categories = [];
        break;
    }

    this.updateSuburbs();

    this.filter.MinBedrooms = this.beds;
    this.filter.MinBathrooms = this.baths;

    this.filter.Suburbs = this.selectedSuburbs.map((s) => s.Value);
    this.filter.Page = 1;

    this.emitFilter();
  }

  suburbsUpdated(selectedSuburbs: MultiSelectOption[]) {
    this.selectedSuburbs = selectedSuburbs;

    this.updateFilter();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }

  doSearch() {
    const params: { [key: string]: string } = {};

    console.log(this.mos);

    if (this.mos === 'RENT') {
      if (this.rentalPriceMin) params.rentalPriceMin = this.rentalPriceMin.toString();
      if (this.rentalPriceMax) params.rentalPriceMax = this.rentalPriceMax.toString();
    } else {
      if (this.salesPriceMin) params.salesPriceMin = this.salesPriceMin.toString();
      if (this.salesPriceMax) params.salesPriceMax = this.salesPriceMax.toString();
    }

    if (this.selectedSuburbs.length) params.suburbs = this.selectedSuburbs.map((s) => s.Value).join('|');

    if (this.beds) params.beds = this.beds.toString();
    if (this.baths) params.baths = this.baths.toString();

    if (this.areaMin) params.areaMin = this.areaMin.toString();
    if (this.areaMax) params.areaMax = this.areaMax.toString();

    let path = '';

    switch (this.mos) {
      case 'BUY':
        path = '/buy';
        break;
      case 'RENT':
        path = '/rent';
        break;
      case 'SOLD':
        path = '/sold';
        break;
      case 'COMMERCIAL':
        path = this.commercialMos === 'rent' ? '/commercial-for-rent' : '/commercial-for-sale';
        break;
      default:
        return;
    }

    console.log(params);

    this.$router.push({
      path: `${path}`,
      query: params,
    });
  }
}
